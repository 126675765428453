import React from "react";
import { sessionsApi, usersApi } from "@/api";
import { IFetchUsersListParams } from "@/api/users/requests.interfaces";
import {
  createFullName,
  EUserStatus,
  IUser,
  IUsersInfo,
  notification,
  useEventsListener,
  usePaginationList,
} from "@/shared";
import { appEvents, AppEvents } from "@/shared/events";
import { getUsersFilter } from "@/store/users";
import _, { isArray } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface IModalState {
  isOpen: boolean;
  message: string;
  onConfirm: () => void;
}

const defaultModalState = {
  isOpen: false,
  message: "",
  onConfirm: () => {},
};

export const useUsersList = () => {
  const [confirmModal, setConfirmModal] = useState<IModalState>(
    defaultModalState
  );
  const [selectedUsers, setSelectedUsers] = useState<Partial<IUser>[]>([]);
  const filter = useSelector(getUsersFilter);

  const paginationList = usePaginationList<any>({
    fetchItems: usersApi.fetchUsersList,
    needInit: false,
    loadParams: {
      limit: 10,
      page: 1,
      sort: "ASC",
      sortField: "lastName",
    },
  });

  useEffect(() => {
    if (paginationList.isInited) {
      const loadParams: Partial<IFetchUsersListParams> = {
        factoryId: filter.factoryId,
        status: filter.isBlocked ? EUserStatus.Blocked : undefined,
      };

      paginationList.setLoadParams(loadParams);
    }
  }, [filter]);

  useEffect(() => {
    const url = new URL(window.location.href);

    const params = {};
    url.searchParams.forEach((it, key) => {
      if (key === "page") params[key] = Number(it);
      else params[key] = it;
    });

    paginationList.resetLoadParams(params);
  }, []);

  const onChangeLoadParams = () => {
    const url = new URL(window.location.href);

    url.searchParams.forEach((_, key) => {
      if (key === "count") return;
      url.searchParams.delete(key);
    });

    Object.keys(paginationList.loadParams)
      .filter((key) => {
        const val = paginationList.loadParams[key];
        return !!val || val === 0;
      })
      .forEach((key) => {
        url.searchParams.set(key, paginationList.loadParams[key]);
      });

    window.history.pushState(null, "", url.toString());
  };

  useEffect(() => {
    onChangeLoadParams();
  }, [paginationList.loadParams]);

  const openConfirmModal = (message: string, onConfirm: () => void) => {
    setConfirmModal({ isOpen: true, message, onConfirm });
  };

  const afterAction = () => {
    setConfirmModal(defaultModalState);
    paginationList.resetFlatList();
  };

  const onDeleteUser = async () => {
    try {
      const { data } = await usersApi.deleteUser(
        selectedUsers.map((it) => it.id)
      );

      setSelectedUsers([]);
      afterAction();

      if (isArray(data.notDeletedUsers) && data.notDeletedUsers.length) {
        const rows = await Promise.all(
          data.notDeletedUsers.map(async (it) => {
            let user: IUser = paginationList.items.find(
              (_it) => Number(_it.id) === Number(it.userId)
            );

            if (!user) {
              try {
                const { data: list } = await usersApi.fetchShortInfoUsersList({
                  limit: 1,
                  includeIds: [Number(it)],
                });
                const item = list.items[0];
                user = {
                  id: item?.id,
                  info: _.omit(item, ["id"]) as IUsersInfo,
                } as IUser;
              } catch (e) {
                console.log("error on fetch users", e);
              }
            }

            return (
              <p key={user?.id} className="txt">
                Користувач:{" "}
                {createFullName(
                  user.info?.firstName,
                  user.info?.middleName,
                  user.info?.lastName
                )}{" "}
                <br />
                Задачі: {it.activeTasksIds.join(", ")}
              </p>
            );
          })
        );

        appEvents.emit("contentModal", {
          title: "Виникла помилка при видаленні деяких користувачів",
          content: (
            <div>
              <p className="subtitle">
                В данних користувача(-ів) є активні задачі.{" "}
              </p>
              {rows}
            </div>
          ),
        });
      }
    } catch (e) {
      console.log("error", e);
      setSelectedUsers([]);
      afterAction();
      notification.showError(
        "Помилка",
        "Виникла помилка, спробуйте, будь ласка, пізніше."
      );
    }
  };

  const onLogoutUser = async () => {
    await sessionsApi.logoutUser(selectedUsers[0]?.id);
    afterAction();
  };

  const onChangeStatus = async (id: number, status: EUserStatus) => {
    await usersApi.changeStatus({ id, status });
    afterAction();
  };

  const onPressBanUser = async () => {
    const user = _.find(
      paginationList.items,
      (it) => it.id === selectedUsers[0]?.id
    );
    if (user.status === EUserStatus.Active)
      openConfirmModal("Ви справді хочете заблокувати?", () =>
        onChangeStatus(user.id, EUserStatus.Blocked)
      );

    if (user.status === EUserStatus.Blocked)
      openConfirmModal("Ви справді хочете розблокувати?", () =>
        onChangeStatus(user.id, EUserStatus.Active)
      );
  };

  const onDeleteUserAvatar = ({ userId }: AppEvents["onDeleteUserAvatar"]) => {
    const items = paginationList.items.map((it) => {
      if (it.id === userId)
        return {
          ...it,
          info: {
            ...it.info,
            avatarUrl: null,
          },
        };
      return it;
    });
    paginationList._setItems(items);
  };

  useEventsListener("onDeleteUserAvatar", onDeleteUserAvatar, [
    paginationList.items,
  ]);

  return {
    paginationList,
    confirmModal: {
      state: confirmModal,
      set: setConfirmModal,
      open: openConfirmModal,
    },
    selectedUsers,
    setSelectedUsers,
    userActions: {
      onDeleteUser,
      onLogoutUser,
      onPressBanUser,
    },
    allIds: paginationList.data?.map((it) => it.id),
  };
};
